import React from "react"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { StoreImage } from "../components/images"

export default () => (
  <Layout>
    <SEO title="Contact Us" meta={[]} />
    <section>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1, padding: "20px" }}>
          <h3>Endeavour Screen Supply</h3>
          <div style={style.container}>
            <div style={style.row}>
              <div className="label email">Email:</div>
              <div className="detail email">
                <a href="mailto:info@endeavourscreens.com.au"> info@endeavourscreens.com.au</a>
              </div>
            </div>
            <div style={style.row}>
              <div className="label phone">Office Ph:</div>
              <div className="detail phone">(07) 55392389</div>
            </div>
            <div style={style.row}>
              <div className="label mobile">Mobile:</div>
              <div className="detail mobile">0413736843</div>
            </div>
            <div style={style.row}>
              <div className="label address">Address:</div>
              <div className="detail address">Unit 3/1 Corporation Rd, Ashmore, QLD 4212</div>
            </div>
          </div>
          <h4>Speak with an Expert</h4>
          <p>Please fill in the form below and we will contact you or to request a quote please click here.</p>
          <form>
            <input name="name" type="text" placeholder="John Smith" />
          </form>
        </div>
        <div style={{ flex: 1, padding: "20px" }}>
          <StoreImage />
          <div className="col-sm-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3523.4225772376153!2d153.383198!3d-27.980964999999998!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b911aae4d84a6b7%3A0xea09b7ef1a25062c!2s3%2F1+Corporation+Rd%2C+Ashmore+QLD+4214%2C+Australia!5e0!3m2!1sen!2sin!4v1424264349057"
              width={"100%"}
              height={450}
              frameBorder={0}
              style={{ border: "none" }}
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

interface StyleProps {
  container: React.CSSProperties
  column: React.CSSProperties
  row: React.CSSProperties
}

const style: StyleProps = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
}
